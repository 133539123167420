import React, { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import {
  flattenPlateContents,
  getWellName,
} from 'client/app/components/PlatePrep/helpers';
import { formatMeasurementObj, formatWellPosition } from 'common/lib/format';
import { PlateContentsMatrix } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import PlateLayout from 'common/ui/components/PlateLayout/PlateLayout';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { getLayoutForWellSelectorWithPlateDimensions } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';

type TooltipView = 'preview' | 'liquids';

type Props = {
  plateType: PlateType;
  wellContents: PlateContentsMatrix;
  liquidColors: LiquidColors;
};

export default function PlatePreview({ plateType, wellContents, liquidColors }: Props) {
  const [view, setView] = useState<TooltipView>('preview');

  const plateState: PlateState = {
    ...makeWellSelector(plateType),
    contents: wellContents,
  };

  return (
    <Stack gap={5} p={3} alignItems="stretch" width={300}>
      <ToggleButtonGroup
        color="primary"
        defaultValue="preview"
        exclusive
        size="small"
        value={view}
        onChange={(_, viewValue) => {
          if (viewValue) {
            setView(viewValue);
          }
        }}
      >
        <ToggleButton size="small" value="preview" sx={{ flex: 1 }}>
          Preview
        </ToggleButton>
        <ToggleButton size="small" value="liquids" sx={{ flex: 1 }}>
          Liquids
        </ToggleButton>
      </ToggleButtonGroup>
      <Content view={view} plateState={plateState} liquidColors={liquidColors} />
    </Stack>
  );
}

type ContentProps = {
  plateState: PlateState;
  liquidColors: LiquidColors;
};

function Content({ view, ...props }: ContentProps & { view: TooltipView }) {
  switch (view) {
    case 'preview':
      return <PreviewContent {...props} />;
    case 'liquids':
      return <LiquidsContent {...props} />;
    default:
      throw new Error(`Unknown view selected: ${view}`);
  }
}

function PreviewContent({ plateState, liquidColors }: ContentProps) {
  const geometry =
    getLayoutForWellSelectorWithPlateDimensions(plateState).getCurrentGeometry(
      plateState,
    );
  const dimensions = geometry.getDimensions();
  return (
    <>
      <Stack gap={3}>
        <Stack direction="row" gap={2}>
          <Typography variant="body1" fontWeight={500} fontSize={11}>
            Type:
          </Typography>
          <Typography variant="body1" fontSize={11}>
            {plateState.type}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2}>
          <Typography variant="body1" fontWeight={500} fontSize={11}>
            Manufacturer:
          </Typography>
          <Typography variant="body1" fontSize={11}>
            {plateState.manufacturer}
          </Typography>
        </Stack>
      </Stack>
      <Svg viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}>
        <PlateLayout
          geometry={geometry}
          plate={plateState}
          liquidColors={liquidColors}
          showAxisLabels={false}
        />
      </Svg>
    </>
  );
}

function LiquidsContent({ plateState, liquidColors }: ContentProps) {
  const plateContents = flattenPlateContents(plateState);
  return (
    <Liquids>
      {!plateContents || plateContents.length === 0 ? (
        <Typography variant="body1" textAlign="center" m="auto">
          No liquids
        </Typography>
      ) : (
        <List>
          {plateContents.map(({ loc, wellContents }, idx) => (
            <ListItem key={idx}>
              <ListItemAvatar>
                <Liquid color={liquidColors.getColorForWell(wellContents)}>
                  {formatWellPosition(loc)}
                </Liquid>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography component="span" variant="body1" mr={3}>
                    {getWellName(wellContents)},
                  </Typography>
                }
                secondary={
                  wellContents.total_volume && (
                    <Typography component="span" variant="body1" fontWeight={500}>
                      {formatMeasurementObj(wellContents.total_volume)}
                    </Typography>
                  )
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </Liquids>
  );
}

//#region Styles

const MAX_CONTENT_HEIGHT = 250;

const Svg = styled('svg')({
  width: '100%',
  maxHeight: MAX_CONTENT_HEIGHT,
});

const Liquid = styled(Avatar, { shouldForwardProp: prop => prop !== 'color' })<{
  color: string;
}>(({ theme, color }) => ({
  color: theme.palette.getContrastText(color),
  backgroundColor: color,
}));

const Liquids = styled(Stack)({
  height: MAX_CONTENT_HEIGHT,
  overflow: 'auto',
});

//#endregion
